export const API_ODOO = 'https://devinass.steamsolutions.tech/';
export const CC_FBO_IMAGES_URL = 'http://172.16.4.100:4001/v1/files?urlName=';
export const CC_NODE_ENV = 'Developer';
export const GOOGLE_RECAPTCH = '6Le0bBMqAAAAAG2YEGl_OO2WuLszmNG4rOuWD4UX';

export const GOOGLE_RECAPTCH_SANDBOX = '6LdkOZQqAAAAAPt7X9kL6hrx7AhO8AlhYvjkaMMq';
export const API_ODOO_SANDBOX = 'https://inass.steamsolutions.tech/';
export const CC_NODE_ENV_SANDBOX = 'Sandbox';

export const GOOGLE_RECAPTCH_PRODUCTION = '6LdkOZQqAAAAAPt7X9kL6hrx7AhO8AlhYvjkaMMq';
export const API_ODOO_PRODUCTION = 'https://registroadultomayor.minaamp.gob.ve:8443/';
export const CC_NODE_ENV_PRODUCTION = 'PRODUCTION';

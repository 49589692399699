<section *ngIf="initial_info" id="login" class="container col-12 col-md-4" style="overflow-y: auto !important">
  <section class="container cc-bg-white rounded shadowBorder p-4">
    <!-- Formualario con los campos de login -->

    <div *ngIf="initial_info">
      <form [formGroup]="initialForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-4">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
        </div>

        <!-- Nombre de usuario -->
        <div class="col-12">
          <cmm-cmp-i-select formControlName="typeDocument" [optionsList]="documentTypeList" [implementCustomInput]="true" placeholder="Ej: V" label="Nacionalidad *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName"></cmm-cmp-i-select>
        </div>

        <div class="col-12" style="font-size: 16px !important">
          <cmm-cmp-i-text formControlName="documentNumber" label="Cédula *" placeholder="Ej: 12345678" [minLength]="6" [maxLength]="8" [onlyNumber]="true" [implementCustomInput]="true"></cmm-cmp-i-text>
        </div>

        <!-- Button -->
        <div class="col-6 text-center mx-auto">
          <cmm-cmp-b-button [button_text]="'Siguiente'" [buttonType]="'cancel'" (click)="toggleView()"></cmm-cmp-b-button>
        </div>
      </form>
    </div>
  </section>
</section>
<section *ngIf="!initial_info" id="login" class="container" style="overflow-y: auto !important">
  <section class="container cc-bg-white rounded shadowBorder p-4">
    <div class="progress-container" [ngStyle]="{ position: 'relative' }">
      <div class="progress-bar" [ngStyle]="{ width: progress + '%' }" [title]="tooltipText">
        <span class="progress-text">{{ tooltipText }}</span>
      </div>
    </div>
    <div *ngIf="basic_info">
      <form [formGroup]="componentForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h3 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h3>
        </div>
        <div *ngIf="age != 0">
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>
        </div>

        <!-- Nombre de usuario -->

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="user_nationality" [optionsList]="documentTypeList" [implementCustomInput]="true" placeholder="Ej: V" label="Nacionalidad *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName" style="color: black"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text formControlName="vat" label="Cédula *" placeholder="Ej: 12345678" [minLength]="6" [maxLength]="10" [onlyNumber]="true" [implementCustomInput]="true" style="color: black"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Nombre y apellido *'" [placeholder]="'Nombre completo'" [minLength]="3" [maxLength]="40" formControlName="name" style="color: black"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select [optionsList]="genderList" [implementCustomInput]="true" placeholder="Ej: Femenino" label="Sexo *" optionKey="genderKey" selectMode="autocomplete" optionName="genderName" formControlName="user_gender"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select [optionsList]="maritalStatusList" [implementCustomInput]="true" placeholder="Ej: Soltero/a" label="Estado Civil *" optionKey="id" selectMode="autocomplete" optionName="name" formControlName="marital_status"> </cmm-cmp-i-select>
        </div>

        <!-- Contenedor para inputs de fecha -->
        <div class="col-12 col-md-4">
          <!-- input de fechas -->
          <cmm-cmp-i-date [label]="'Fecha de nacimiento'" [typeFilterDate]="1" toLocaleDateString="en-US" placeholder="Ej: 23/12/2023" formControlName="birthdate"></cmm-cmp-i-date>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Correo electrónico'" [placeholder]="'Ingrese el correo'" [minLength]="3" [maxLength]="50" formControlName="email"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select [optionsList]="ethnicityList" [implementCustomInput]="true" placeholder="Ej: Ninguna" label="Pueblo indígena *" optionKey="id" selectMode="autocomplete" optionName="name" formControlName="ethnicity"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select [optionsList]="educationalLevelList" [implementCustomInput]="true" placeholder="Ej: Primaria" label="Nivel Educativo *" optionKey="id" selectMode="autocomplete" optionName="name" formControlName="educational_level"> </cmm-cmp-i-select>
        </div>

        <!-- <div class="col-12 col-md-4"></div> -->

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="phoneOperator" [optionsList]="phoneOperatorList" [implementCustomInput]="true" placeholder="Ej: 0424" label="Operadora *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text label="Teléfono *" placeholder="Ej: 1234567" [minLength]="7" [maxLength]="7" [onlyNumber]="true" [implementCustomInput]="true" formControlName="phone"> </cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text label="Teléfono alternativo" placeholder="Ej: 04241234567" [minLength]="11" [maxLength]="11" [onlyNumber]="true" [implementCustomInput]="true" formControlName="phone2"> </cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select-list [optionsList]="stateList" [implementCustomInput]="true" placeholder="Ej: Distrito Capital" label="Estado *" optionKey="id" selectMode="select" optionName="name" formControlName="state_id" (inputChange)="onSelectionChange()"> </cmm-cmp-i-select-list>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select-list [optionsList]="municipalityList" [implementCustomInput]="true" placeholder="Ej: Libertador" label="Municipio *" optionKey="id" selectMode="select" optionName="name" formControlName="municipality_id" (inputChange)="onSelectionChange2()"> </cmm-cmp-i-select-list>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-select-list [optionsList]="parishList" [implementCustomInput]="true" placeholder="Ej: 23 de enero" label="Parroquia" optionKey="id" selectMode="select" optionName="name" formControlName="parish_id"> </cmm-cmp-i-select-list>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Calle o Avenida *'" [placeholder]="'Av. San Martin'" [minLength]="3" [maxLength]="40" formControlName="street"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Sector'" [placeholder]="'San Martin'" [minLength]="3" [maxLength]="40" formControlName="street2"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Numero de casa o Apto.'" [placeholder]="'Casa numero 3'" [minLength]="3" [maxLength]="40" formControlName="house_number"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Punto de referencia'" [placeholder]="'Cerca de'" [minLength]="3" [maxLength]="40" formControlName="reference_point"></cmm-cmp-i-text>
        </div>

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row justify-content-center">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackInitialInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Siguiente'" [buttonType]="'cancel'" (submit)="basicInfoView()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="socioeconomic_data">
      <form [formGroup]="socialForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>

          <h5 class="cc-txt">Datos socioeconómicos</h5>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>1. ¿Con quién vive usted? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let livingWith of livingWithOptions" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateLivingWithOption(livingWith, $event.checked)" [checked]="isLivingWithSelected(livingWith)">
                  {{ livingWith }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="otherLivingWith" class="col-12 col-md-6 mx-auto">
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherLivingWith"></cmm-cmp-i-text>
          </div>

          <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>2. ¿Recibe ingresos económicos de manera permanente? *</h4>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="checkIncome()" formControlName="permanentIncome" value="si" id="permanentIncomeYes" />
              <label class="form-check-label" for="permanentIncomeYes">Sí</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="checkIncome()" formControlName="permanentIncome" value="no" id="permanentIncomeNo" />
              <label class="form-check-label" for="permanentIncomeNo">No</label>
            </div>
          </div>
          <div *ngIf="!isValidIncome" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>3. Seleccione por cuál de estos conceptos recibe usted ingresos económicos: *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let incomeSource of incomeSources" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateIncomeSource(incomeSource, $event.checked)" [checked]="isIncomeSourceSelected(incomeSource)">
                  {{ incomeSource }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="incomeErrorMessage" class="alert alert-danger">
            {{ incomeErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>4. El ingreso que percibe le alcanza para: *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let expenseConcepts of expenseConcept" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateExpenseConcept(expenseConcepts, $event.checked)" [checked]="isExpenseConceptSelected(expenseConcepts)">
                  {{ expenseConcepts }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="otherExpenseSource" class="col-12 col-md-6 mx-auto">
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherExpenseSource"></cmm-cmp-i-text>
          </div>

          <div *ngIf="expenseErrorMessage" class="alert alert-danger">
            {{ expenseErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>5. Vivienda: *</h4>
            <div class="form-check" *ngFor="let condition of housingConditions">
              <input class="form-check-input" type="radio" (change)="checkHousing()" formControlName="housingCondition" [value]="condition.id" [id]="'housingCondition' + condition.id" />
              <label class="form-check-label" [for]="'housingCondition' + condition.id">{{ condition.name }}</label>
            </div>
          </div>
          <div *ngIf="!isValidHousing" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>6. Tipo de vivienda: *</h4>
            <div class="form-check" *ngFor="let type of housingTypes">
              <input class="form-check-input" type="radio" (change)="selectionHousingType()" formControlName="housingType" [value]="type.id" [id]="'housingType' + type.id" />
              <label class="form-check-label" [for]="'housingType' + type.id">{{ type.name }}</label>
            </div>
          </div>
          <div *ngIf="!isValidHousingType" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div *ngIf="housingOtherType" class="col-12 col-md-6 mx-auto">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="housingOtherType"></cmm-cmp-i-text>
        </div>

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackBasicInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Siguiente'" [buttonType]="'cancel'" (submit)="toggleSocioeconomicData()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="basicHealthData">
      <form [formGroup]="healthForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>

          <h5 class="cc-txt">Datos Básicos de Salud</h5>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>7. ¿Tiene alguna discapacidad? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let disability of disabilities" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateDisabilityOption(disability, $event.checked)" [checked]="isDisabilitySelected(disability)">
                  {{ disability }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="disabilityErrorMessage" class="alert alert-danger">
            {{ disabilityErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>8. ¿Usted posee alguna de estas Enfermedades? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let disease of diseases" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateDiseaseOption(disease, $event.checked)" [checked]="isDiseaseSelected(disease)">
                  {{ disease }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="otherDisease" class="col-12 col-md-6 mx-auto">
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherDisease"></cmm-cmp-i-text>
          </div>

          <div *ngIf="diseaseErrorMessage" class="alert alert-danger">
            {{ diseaseErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>9. ¿Recibe tratamiento o rehabilitación en algún centro médico? *</h4>
            <div class="form-check" *ngFor="let type of treatmentStatus">
              <input class="form-check-input" type="radio" (change)="checkTreatment()" formControlName="treatmentStatus" [value]="type.value" [id]="'treatmentStatus' + type.value" />
              <label class="form-check-label" [for]="'treatmentStatus' + type.value">{{ type.label }}</label>
            </div>
          </div>
          <div *ngIf="!isValidTreatment" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>10. Indique dónde recibe su atención de salud: *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let treatmentCenter of treatmentCenters" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateTreatmentCenterOption(treatmentCenter, $event.checked)" [checked]="isTreatmentCenterSelected(treatmentCenter)">
                  {{ treatmentCenter }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="treatmentCenterErrorMessage" class="alert alert-danger">
            {{ treatmentCenterErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>11. ¿Usted se encuentra institucionalizado en algún centro residencial? *</h4>
            <div class="form-check" *ngFor="let option1 of isInstitutionalizedResidencyCenter">
              <input (change)="updateInstitutionalResidency(option1.id)" class="form-check-input" type="radio" formControlName="isInstitutionalizedResidencyCenter" [value]="option1.id" [id]="'isInstitutionalizedResidencyCenter' + option1.id" />
              <label class="form-check-label" [for]="'isInstitutionalizedResidencyCenter' + option1.id">{{ option1.name }}</label>
            </div>
          </div>
          <div *ngIf="institutionalResidency" class="col-12 col-md-6 mx-auto">
            <h4>Indique en cual centro residencial *</h4>
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Centro de residencia'" [placeholder]="'Centro de residencia'" [minLength]="3" [maxLength]="40" formControlName="institutionalResidency"></cmm-cmp-i-text>
          </div>
          <div *ngIf="!isValidResidency" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>12. ¿Usted tiene acceso a los alimentos CLAP en su Comunidad? *</h4>
            <div class="form-check" *ngFor="let option2 of clapBenefitOptions">
              <input (change)="updateClapFrecuency(option2.value)" class="form-check-input" type="radio" formControlName="clapBenefit" [value]="option2.value" [id]="'clapBenefit' + option2.value" />
              <label class="form-check-label" [for]="'clapBenefit' + option2.value">{{ option2.label }}</label>
            </div>
          </div>
          <div *ngIf="clapFrecuency" class="col-12 col-md-6 mx-auto">
            <h4>Indique la frecuencia:</h4>
            <div class="form-check" *ngFor="let option3 of clapBenefitFrequency">
              <input class="form-check-input" type="radio" (change)="checkFrecuencyBenefitClap()" formControlName="clapBenefitFrequency" [value]="option3.id" [id]="'clapBenefitFrequency' + option3.id" />
              <label class="form-check-label" [for]="'clapBenefitFrequency' + option3.id">{{ option3.name }}</label>
            </div>
          </div>
          <div *ngIf="!isValidClapBenefit" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>13. ¿Usted recibe el complemento CLAP por los Círculos de abuelos? *</h4>
            <div class="form-check" *ngFor="let option of clapComplementOptions">
              <input (change)="updateClapComplementFrecuency(option.value)" class="form-check-input" type="radio" formControlName="clapComplementOptions" [value]="option.value" [id]="'clapComplementOptions' + option.value" />
              <label class="form-check-label" [for]="'clapComplementOptions' + option.value">{{ option.label }}</label>
            </div>
          </div>
          <div *ngIf="clapComplementFrecuency" class="col-12 col-md-6 mx-auto">
            <h4>Indique la frecuencia:</h4>
            <div class="form-check" *ngFor="let option4 of clapComplementFrequency">
              <input class="form-check-input" type="radio" (change)="checkFrecuencyComplementClap()" formControlName="clapComplementFrecuency" [value]="option4.id" [id]="'clapComplementFrequency' + option4.id" />
              <label class="form-check-label" [for]="'clapComplementFrequency' + option4.id">{{ option4.name }}</label>
            </div>
          </div>
          <div *ngIf="!isValidClapComplement" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>14. ¿Con qué frecuencia se alimenta usted? *</h4>
            <div class="form-check" *ngFor="let option of foodFrequencyOptions">
              <input class="form-check-input" type="radio" (change)="checkValidFood()" formControlName="foodFrequency" [value]="option.id" [id]="'foodFrequency' + option.id" />
              <label class="form-check-label" [for]="'foodFrequency' + option.id">{{ option.name }}</label>
            </div>
          </div>
          <div *ngIf="!isValidFood" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackSocialInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Siguiente'" [buttonType]="'cancel'" (submit)="toggleHealthData()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="social_participation">
      <form [formGroup]="socialPartipitationForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>

          <h5 class="cc-txt">Participación Social</h5>
        </div>

        <!-- <div class="col-12 col-md-10 mx-auto">
          <div class="text-start">
            <h4>15. ¿Con qué frecuencia utiliza usted los siguientes medios de comunicación? *</h4>
          </div>
          <div class="row">
            <div class="col-12 col-md-6" *ngFor="let opcion of option_fb">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">{{ opcion }}</h5>
                  <div class="col-12">
                    <cmm-cmp-i-select [optionsList]="frequencyList" [implementCustomInput]="true" placeholder="Seleccione" label="Frecuencia *" optionKey="name" selectMode="autocomplete" optionName="name" (inputChange)="actualizarRespuesta2(opcion, $event)" formControlName="frequencySelection">
                    </cmm-cmp-i-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isValid" class="alert alert-danger">Por favor, complete todas las opciones antes de continuar.</div>
        </div> -->

        <div class="col-12 col-md-10 mx-auto">
          <div class="text-start">
            <h4>15. ¿Utiliza usted los siguientes medios de comunicación? *</h4>
          </div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Medio de Comunicación</th>
                <th scope="col">Sí</th>
                <th scope="col">No</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let opcion of option_fb">
                <td>{{ opcion }}</td>
                <td>
                  <input type="radio" name="{{ opcion }}" [value]="true" (change)="actualizarRespuesta2(opcion, true)" [checked]="response_fb[opcion] === true" />
                </td>
                <td>
                  <input type="radio" name="{{ opcion }}" [value]="false" (change)="actualizarRespuesta2(opcion, false)" [checked]="response_fb[opcion] === false" />
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="!isValid" class="alert alert-danger">Por favor, complete todas las opciones antes de continuar.</div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>16. ¿Participa usted en alguna Organización Social? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let opcion of organization" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateOrganizationOption(opcion, $event.checked)" [checked]="isOrganizationSelected(opcion)">
                  {{ opcion }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="otherSocialOrganization" class="col-12 col-md-6 mx-auto">
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherSocialOrganization"></cmm-cmp-i-text>
          </div>

          <div *ngIf="errorOrganizationMessage" class="alert alert-danger">
            {{ errorOrganizationMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>17. ¿Ha sido beneficiado en el último año por alguna Misión Social? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let opcion of social_mission" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateSocialMissionOption(opcion, $event.checked)" [checked]="isSocialMissionSelected(opcion)">
                  {{ opcion }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="errorMisionMessage" class="alert alert-danger">
            {{ errorMisionMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>18. ¿Le gustaría participar en las siguientes actividades? *</h4>
          </div>

          <mat-accordion class="row">
            <div *ngFor="let opcion of activities" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateActivityOption(opcion, $event.checked)" [checked]="isActivitySelected(opcion)">
                  {{ opcion }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <!-- Error message -->
          <div *ngIf="errorActivitiesMessage" class="alert alert-danger mt-3">
            {{ errorActivitiesMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>19. Indique sus principales habilidades y destrezas: *</h4>
          </div>

          <mat-accordion class="row">
            <!-- Contenedor expandible por cada módulo -->
            <div *ngFor="let opcion of habilities" class="col-sm-12 col-sm-6 col-md-4 px-0 py-2">
              <!-- Contenedor del título -->
              <mat-panel-title>
                <mat-checkbox class="d-flex" (change)="updateHabilitiesOption(opcion, $event.checked)" [checked]="isHabilitySelected(opcion)">
                  <!-- Nombre de la habilidad -->
                  {{ opcion }}
                </mat-checkbox>
              </mat-panel-title>
            </div>
          </mat-accordion>

          <div *ngIf="otherHabilities" class="col-12 col-md-6 mx-auto">
            <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherHabilities"></cmm-cmp-i-text>
          </div>

          <!-- Mensaje de error -->
          <div *ngIf="habilitiesErrorMessage" class="alert alert-danger mt-3">
            {{ habilitiesErrorMessage }}
          </div>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>20. ¿Le gustaría compartir sus conocimientos, saberes y tradiciones? *</h4>
            <div class="form-check" *ngFor="let option of shareKnowledge">
              <input class="form-check-input" type="radio" (change)="checkShare()" formControlName="shareKnowledge" [value]="option.value" [id]="'shareKnowledge' + option.value" />
              <label class="form-check-label" [for]="'shareKnowledge' + option.value">{{ option.label }}</label>
            </div>
          </div>
          <div *ngIf="!isShareKnowledge" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <!-- <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>16. ¿Conoce la Ley Orgánica para la Atención y Desarrollo Integral de las Personas Adultas Mayores? *</h4>
            <h5>
              Esta Ley tiene por objeto garantizar el respeto a la dignidad humana de las personas adultas mayores y el pleno ejercicio de sus derechos y garantías, el cumplimiento de sus deberes y responsabilidades, reconociendo su autonomía y libre desenvolvimiento de la personalidad, a través de
              la atención integral que deben brindarle el Estado, las familias y la sociedad para asegurar su buen vivir, bienestar, calidad de vida, seguridad y envejecimiento saludable, activo, digno y feliz.
            </h5>
            <div class="form-check" *ngFor="let option of lawAtentionOptions">
              <input class="form-check-input" type="radio" formControlName="lawAtention" [value]="option.value" [id]="'lawAtention' + option.value" />
              <label class="form-check-label" [for]="'lawAtention' + option.value">{{ option.label }}</label>
            </div>
          </div>
        </div> -->

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>21. La información fue suministrada por: *</h4>
            <div class="form-check" *ngFor="let source of infoSources">
              <input class="form-check-input" type="radio" (change)="checkInfo()" formControlName="info_suministrate" [value]="source.value" [id]="'info_suministrate' + source.value" />
              <label class="form-check-label" [for]="'info_suministrate' + source.value">{{ source.label }}</label>
            </div>
          </div>
          <div *ngIf="!isInfoSuministrate" class="alert alert-danger">Por favor, seleccione una opción.</div>
        </div>

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackHealthInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Finalizar registro'" [buttonType]="'cancel'" (submit)="toggleSocialData()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="family_data">
      <form [formGroup]="familyForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>

          <h5 class="cc-txt">Datos del familiar o amigo</h5>
        </div>

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="user_nationality" [optionsList]="documentTypeList" [implementCustomInput]="true" placeholder="Ej: V" label="Doc. *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName" (inputChange)="checkIdentificationFamily()">
          </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text formControlName="vat" label="Nro. de documento *" placeholder="Ej: 12345678" [minLength]="6" [maxLength]="10" [onlyNumber]="true" [implementCustomInput]="true" (input)="checkIdentificationFamily()"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Nombre y apellido *'" [placeholder]="'Nombre completo'" [minLength]="3" [maxLength]="40" formControlName="name"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="phoneOperator" [optionsList]="phoneOperatorList" [implementCustomInput]="true" placeholder="Ej: 0424" label="Operadora *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text label="Teléfono *" placeholder="Ej: 1234567" [minLength]="7" [maxLength]="7" [onlyNumber]="true" [implementCustomInput]="true" formControlName="phone"> </cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>Parentesco *</h4>
            <div class="form-check" *ngFor="let relation of relations">
              <input class="form-check-input" (change)="selectionParentType()" type="radio" formControlName="parentType" [value]="relation.id" [id]="relation.id" />
              <label class="form-check-label" [for]="relation.id">{{ relation.name }}</label>
            </div>
          </div>
        </div>

        <div *ngIf="otherParents" class="col-12 col-md-6 mx-auto">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Otro'" [placeholder]="'Otro'" [minLength]="3" [maxLength]="40" formControlName="otherParents"></cmm-cmp-i-text>
        </div>

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackSocialParticipationInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Finalizar registro'" [buttonType]="'cancel'" (submit)="toggleFamilyData()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="worker_data">
      <form [formGroup]="inassForm" class="row" autocomplete="off">
        <!-- Imagen y Titulo -->
        <div class="col-12 text-center mb-1">
          <!-- Titulo  -->
          <h4 class="cc-txt">REGISTRO NACIONAL DE PERSONAS ADULTAS MAYORES</h4>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.name }}</h6>
          <h6 class="cc-txt" style="color: black">{{ create_inass_user.user_nationality }}-{{ create_inass_user.vat }}</h6>
          <h6 class="cc-txt" style="color: black">{{ gender }} {{ age }} años</h6>

          <h5 class="cc-txt">Datos del operador</h5>
        </div>

        <!-- Nombre de usuario -->

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="user_nationality" [optionsList]="documentTypeList" [implementCustomInput]="true" placeholder="Ej: V" label="Doc. *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName" (inputChange)="checkIdentificationFamily()">
          </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text formControlName="vat" label="Nro. de documento *" placeholder="Ej: 12345678" [minLength]="6" [maxLength]="10" [onlyNumber]="true" [implementCustomInput]="true" (input)="checkIdentificationFamily()"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" class="my-2" [label]="'Nombre y apellido *'" [placeholder]="'Nombre completo'" [minLength]="3" [maxLength]="40" formControlName="name"></cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-2">
          <cmm-cmp-i-select formControlName="phoneOperator" [optionsList]="phoneOperatorList" [implementCustomInput]="true" placeholder="Ej: 0424" label="Operadora *" optionKey="documentKey" selectMode="autocomplete" optionName="documentTypeName"> </cmm-cmp-i-select>
        </div>

        <div class="col-12 col-md-4">
          <cmm-cmp-i-text label="Teléfono *" placeholder="Ej: 1234567" [minLength]="7" [maxLength]="7" [onlyNumber]="true" [implementCustomInput]="true" formControlName="phone"> </cmm-cmp-i-text>
        </div>

        <div class="col-12 col-md-6">
          <cmm-cmp-i-text [implementCustomInput]="true" placeholder="Ej: Sede Administrativa" label="Lugar de recolección *" [minLength]="3" [maxLength]="40" formControlName="collectionPlace"> </cmm-cmp-i-text>
        </div>

        <div class="col-12">
          <cmm-cmp-i-text [implementCustomInput]="true" placeholder="Ej: Familiar" label="Indique la relación  que tiene ud con el adulto mayor *" [minLength]="3" [maxLength]="40" formControlName="staffRelationship"> </cmm-cmp-i-text>
        </div>

        <!-- <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="text-start">
            <h4>Razón de captación de la información *</h4>
            <div class="form-check" *ngFor="let reason of captureReasons">
              <input class="form-check-input" type="radio" [formControlName]="'captureReason'" [value]="reason.id" [id]="reason.id" />
              <label class="form-check-label" [for]="reason.id">{{ reason.name }}</label>
            </div>
          </div>
        </div> -->

        <!-- Button -->
        <div class="col-12 col-md-10 mx-auto pt-4">
          <div class="row">
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Volver atrás'" [buttonType]="'warningOutline'" (click)="goBackSocialParticipationInfo()"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Reiniciar formulario'" (click)="reloadView()" [buttonType]="'warning'"></cmm-cmp-b-button>
            </div>
            <div class="col-12 col-md-4 text-center mb-2">
              <cmm-cmp-b-button class="btn-custom" [button_text]="'Finalizar registro'" [buttonType]="'cancel'" (submit)="toggleInassData()"></cmm-cmp-b-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="registerCompleted" class="text-center">
      <img [src]="check" alt="Check" style="width: 100px; height: 100px" />
      <h3 class="cc-txt">Registro exitoso</h3>
      <h4 class="cc-txt">{{ currentDate | date : 'hh:mm a dd/MM/yyyy' }}</h4>
      <div class="col-12 col-md-10 mx-auto pt-4">
        <div class="row">
          <div class="col-12 col-md-5 text-center text-md-left mb-3 mb-md-0">
            <cmm-cmp-b-button class="btn-custom" [button_text]="'Descargar comprobante'" [buttonType]="'cancelOutline'" (click)="getPdf()"></cmm-cmp-b-button>
          </div>
          <div class="col-12 col-md-2"></div>
          <div class="col-12 col-md-5 text-center text-md-right mb-3 mb-md-0">
            <cmm-cmp-b-button class="btn-custom" [button_text]="'Nuevo registro'" [buttonType]="'cancel'" (click)="reloadView()"></cmm-cmp-b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './pages/auth-layout/auth-layout.component';
import { AuthLoginFormComponent } from './components/auth-login-form/auth-login-form.component';
import { BaseGuard } from 'src/app/core/guards/base.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [BaseGuard],
    children: [
      {
        path: '',
        redirectTo: 'form',
        pathMatch: 'full',
      },
      {
        path: 'form',
        component: AuthLoginFormComponent,
      },
      { path: '**', redirectTo: 'form', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [BaseGuard],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

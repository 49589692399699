/**
 * Este archivo contiene las variables exportadas con cada ruta de las imagenes que se usen en common
 */

/**
 * COVERS
 */
export const background = '../app/common/assets/images/logos/fondo-inass-2x.png';

/**
 * DIALOGS
 */

/**
 * LOGOS
 */
export const inass = '../app/common/assets/images/logos/inass.jpg';
export const qr_amm = '../app/common/assets/images/logos/gaceta-ley-amm.png';
export const banner = '../app/common/assets/images/logos/logo-inass-2x.png';
export const footer = '../app/common/assets/images/logos/footer.jpg';
export const check = '../app/common/assets/images/logos/check.jpeg';
export const tittle = '../app/common/assets/images/logos/registro-2x.png';

/**
 * ICONS
 */

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { inass, qr_amm, check } from 'src/app/common/assets/images/images-routes';
import { CmmComponentFormModel } from 'src/app/common/data/forms/models/form.model';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { timeout } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CmmAlertToastrModel, CmmAlertModalModel } from 'src/app/common/data/dialogs/models/dialogs.model';
import { CmmDialogService } from 'src/app/common/services/dialogs.service';

interface LivingOption {
  id: number;
  name: string;
}

interface ControlGroup {
  [key: string]: any;
}

@Component({
  selector: 'cmp-auth-login-form',
  templateUrl: './auth-login-form.component.html',
  styleUrls: ['./auth-login-form.component.scss'],
})
export class AuthLoginFormComponent implements CmmComponentFormModel {
  $unsubscribe = new Subject<void>();
  expiratedSessionDialogOpen: boolean = false;

  recaptcha: string = '';

  isFieldDisabled: boolean = true;
  progress: number = 0;
  tooltipText: string = '0%';
  age = 0;
  gender: string = '';

  componentForm: FormGroup = new FormGroup([]);
  initialForm: FormGroup = new FormGroup([]);
  ingresosForm: FormGroup = new FormGroup([]);
  socialForm: FormGroup = new FormGroup([]);
  healthForm: FormGroup = new FormGroup([]);
  socialPartipitationForm: FormGroup = new FormGroup([]);
  familyForm: FormGroup = new FormGroup([]);
  inassForm: FormGroup = new FormGroup([]);
  nameOperatorForm: string = '';
  nameFamilyForm: string = '';

  currentDate: Date = new Date();

  documentTypeList: any[] = [];
  genderList: any[] = [];
  maritalStatusList: any[] = [];
  phoneOperatorList: any[] = [];
  stateList: any[] = [];
  municipalityList: any[] = [];
  municipalityListAll: any[] = [];
  parishList: any[] = [];
  parishListAll: any[] = [];
  selectedOption: string = '';
  ethnicityList: any[] = [];
  educationalLevelList: any[] = [];

  socioeconomic_data: boolean = false;
  basicHealthData: boolean = false;
  social_participation: boolean = false; // Cambio de nombre de variable
  family_data: boolean = false;
  worker_data: boolean = false;
  basic_info: boolean = false;
  initial_info: boolean = true;
  registerCompleted: boolean = false;

  livingWithOptions: string[] = [];
  livingWithAllOptions: any[] = [];
  selectedOptions: string[] = [];
  selectedLivingWithIds: number[] = [];
  errorMessage: string = '';

  incomeSources: string[] = [];
  incomeSourcesAll: any[] = [];
  selectedIncomeSources: string[] = [];
  selectedIncomeSourceIds: number[] = [];
  incomeErrorMessage: string = '';

  expenseConcept: string[] = [];
  expenseConceptAll: any[] = [];
  selectedExpenseConcept: string[] = [];
  selectedExpenseConceptIds: number[] = [];
  expenseErrorMessage: string = '';
  otherExpenseSource: boolean = false;

  disabilities: string[] = [];
  allDisabilities: any[] = [];
  selectedDisabilities: string[] = [];
  disabilityErrorMessage: string | null = null;
  selectedDisabilitiesWithIds: number[] = [];

  diseases: string[] = [];
  allDiseases: any[] = [];
  selectedDiseases: string[] = [];
  diseaseErrorMessage: string | null = null;
  selecteddiseasesWithIds: number[] = [];
  otherDisease: boolean = false;

  treatmentCenters: string[] = [];
  allTreatmentCenters: any[] = [];
  selectedTreatmentCenters: string[] = [];
  treatmentCenterErrorMessage: string | null = null;
  selectedTreatmentCentersWithIds: number[] = [];

  selectedTreatment: string | null = null;
  treatmentErrorMessage: string | null = null;

  selectedFoodFrequency: string | null = null;

  option_fb: string[] = [];
  // response_fb: { [key: string]: string } = {};
  response_fb: { [key: string]: boolean } = {}; // Diccionario para almacenar respuestas

  activities: string[] = [];
  allActivities: any[] = [];
  response_activities: { [key: string]: string } = {};

  organization: string[] = [];
  allOrganization: any[] = [];
  otherSocialOrganization: boolean = false;

  social_mission: string[] = [];
  socialMisionsAll: any[] = [];

  habilities: string[] = [];
  allHabilities: any[] = [];

  info_suministrate: string = '';

  inass = inass;
  qr_amm = qr_amm;
  check = check;

  token: string | undefined;

  latitude: any = 0.0;
  longitude: any = 0.0;

  create_inass_user: any = {};

  selectedLivingWithOptions: string[] = [];
  otherLivingWith: boolean = false;

  housingConditions: any[] = [];

  housingTypes: any[] = [];
  housingOtherType: boolean = false;
  otherParents: boolean = false;

  treatmentStatus = [
    { label: 'Sí', value: 'si' },
    { label: 'No', value: 'no' },
    { label: 'No es necesario', value: 'no_necesario' },
  ];

  isInstitutionalizedResidencyCenter: any[] = [];

  institutionalResidency: boolean = false;

  clapFrecuency: boolean = false;

  clapComplementFrecuency: boolean = false;

  frequencyList: any[] = [];

  shareKnowledge = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  infoSources = [
    { label: 'Directamente por el o la adulta mayor', value: 'self' },
    { label: 'Por un familiar o amigo', value: 'family' },
    // { label: 'Servidor público', value: 'staff' },
  ];

  treatmentOptions = [
    { value: 'si', label: 'Sí' },
    { value: 'no', label: 'No' },
    { value: 'no_es_necesario', label: 'No es necesario' },
  ];

  clapBenefitOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  clapComplementOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  clapBenefitFrequency: any[] = [];

  clapComplementFrequency: any[] = [];

  lawAtentionOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  foodFrequencyOptions: any[] = [];

  relations: any[] = [];

  campusList: any[] = [];

  captureReasons: any[] = [];

  inass_user_id: number = 0;
  inass_survey_id: number = 110;

  id_hash_pdf: string = '9f7d9fe9-b0c7-4e52-8c7c-fc73c24f2270';

  isValid: boolean = true;
  isValidIncome: boolean = true;
  isValidHousing: boolean = true;
  isValidHousingType: boolean = true;
  isValidTreatment: boolean = true;
  isValidResidency: boolean = true;
  isValidClapBenefit: boolean = true;
  isValidClapComplement: boolean = true;
  isValidFood: boolean = true;
  isInfoSuministrate: boolean = true;
  isShareKnowledge: boolean = true;

  selectedOrganizationOptions: string[] = [];

  selectedSocialMissionOptions: string[] = [];

  selectedActivityOptions: string[] = [];

  selectedHabilitiesOptions: string[] = [];
  habilitiesErrorMessage: string = '';
  errorOrganizationMessage: string = '';
  errorMisionMessage: string = '';
  errorActivitiesMessage: string = '';
  otherHabilities: boolean = false;

  selectedOrganizationsIds: number[] = [];
  selectedActivityIds: number[] = [];
  selectedSocialIds: number[] = [];
  skillsIds: number[] = [];

  timeout: any;

  // pdfUrl: string | null = null;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private recaptchaV3Service: ReCaptchaV3Service, private dialogService: CmmDialogService, private tokenService: TokenService) {
    this.token = undefined;
  }

  public send(form: NgForm): void {
    this.recaptchaV3Service.execute('6Le0bBMqAAAAAG2YEGl_OO2WuLszmNG4rOuWD4UX').subscribe((token) => {
      // Include the token in your form data or perform necessary actions
    });
  }

  async checkIdentification(): Promise<void> {
    const identificationData = {
      document_type: this.initialForm.value.typeDocument,
      identification_id: this.initialForm.value.documentNumber, // ID de identificación del usuario
    };

    try {
      const response = await this.authService.checkIdentificationService(identificationData).toPromise(); // Convertimos el observable en una promesa

      // Si se obtiene una respuesta exitosa, actualizamos los valores del formulario
      this.componentForm.get('name')?.disable();
      this.componentForm.get('birthdate')?.disable();
      this.componentForm.get('user_gender')?.disable();
      this.componentForm.patchValue({
        name: response.result?.Nombre ? response.result.Nombre : this.componentForm.get('name')?.enable(),
        vat: identificationData.identification_id,
        user_nationality: identificationData.document_type,
        user_gender: response.result?.sexo ? response.result?.sexo : this.componentForm.get('user_gender')?.enable(),
        birthdate: response.result?.fecha_de_nacimiento ? response.result.fecha_de_nacimiento : this.componentForm.get('birthdate')?.enable(),
      });

      this.create_inass_user = {
        name: response.result?.Nombre || '', // Asignar el valor del nombre obtenido de la API al campo 'name'
        vat: identificationData.identification_id,
        user_nationality: identificationData.document_type,
        user_gender: response.result?.sexo || '',
        birthdate: response.result?.fecha_de_nacimiento,
      };

      this.gender = response.result?.sexo == 'female' ? 'Femenino' : 'Masculino';

      // Cálculo de la edad
      if (response.result.fecha_de_nacimiento) {
        const today = new Date();
        const birthDate = new Date(response.result.fecha_de_nacimiento);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        this.age = age;
      } else {
        this.age = 0;
      }
    } catch (error) {
      console.error('Error al llamar a la API:', error);
      this.componentForm.get('name')?.enable();
      this.componentForm.get('birthdate')?.enable();
      this.componentForm.get('user_gender')?.enable();
      this.create_inass_user = {
        vat: identificationData.identification_id,
        user_nationality: identificationData.document_type,
      };
    }
  }

  async checkIdentificationInass(): Promise<void> {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.inassForm.value.user_nationality && this.inassForm.value.vat) {
        const identificationData = {
          document_type: this.inassForm.value.user_nationality,
          identification_id: this.inassForm.value.vat, // ID de identificación del usuario
        };

        await this.authService
          .checkIdentificationService(identificationData)
          .pipe(
            timeout(5000) // 5000 ms = 5 segundos
          )
          .subscribe(
            (response) => {
              this.inassForm.get('name')?.disable();
              this.inassForm.patchValue({
                name: response.result?.Nombre ? response.result.Nombre : this.inassForm.get('name')?.enable(), // Asignar el valor del nombre obtenido de la API al campo 'name'
              });
              this.nameOperatorForm = response.result?.Nombre;

              // Manejar la respuesta de la API según tus necesidades
            },
            (error) => {
              console.error('Error al llamar a la API:', error);
              this.inassForm.get('name')?.enable();
            }
          );
      }
    }, 5000); // 5000 ms = 5 segundos
  }

  async checkIdentificationFamily(): Promise<void> {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.familyForm.value.user_nationality && this.familyForm.value.vat) {
        const identificationData = {
          document_type: this.familyForm.value.user_nationality,
          identification_id: this.familyForm.value.vat, // ID de identificación del usuario
        };

        await this.authService
          .checkIdentificationService(identificationData)
          .pipe(
            timeout(5000) // 5000 ms = 5 segundos
          )
          .subscribe(
            (response) => {
              this.familyForm.get('name')?.disable();
              this.familyForm.patchValue({
                name: response.result?.Nombre ? response.result.Nombre : this.familyForm.get('name')?.enable(), // Asignar el valor del nombre obtenido de la API al campo 'name'
              });
              this.nameFamilyForm = response.result?.Nombre;

              // Manejar la respuesta de la API según tus necesidades
            },
            (error) => {
              console.error('Error al llamar a la API:', error);
              this.familyForm.get('name')?.enable();
            }
          );
      }
    }, 5000); // 5000 ms = 5 segundos
  }

  updateLivingWithResponse(event: any, option: string): void {
    const isChecked = event.checked;

    if (option === 'Solo/Sola' && isChecked) {
      // Si se selecciona 'Solo/Sola', desmarcar todas las otras opciones
      this.selectedOptions = ['Solo/Sola'];
    } else if (option === 'Solo/Sola' && !isChecked) {
      // Si se desmarca 'Solo/Sola', simplemente quitarla de la lista de seleccionados
      this.selectedOptions = this.selectedOptions.filter((opt) => opt !== 'Solo/Sola');
    } else {
      // Si se selecciona una opción diferente de 'Solo/Sola'
      if (isChecked) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = this.selectedOptions.filter((opt) => opt !== option);
      }
      // Desmarcar 'Solo/Sola' si alguna otra opción está seleccionada
      this.selectedOptions = this.selectedOptions.filter((opt) => opt !== 'Solo/Sola');
    }
  }

  actualizarRespuesta2(opcion: string, respuesta: boolean) {
    this.response_fb[opcion] = respuesta;
    this.validarRespuestas();
  }

  validarRespuestas() {
    this.isValid = Object.keys(this.response_fb).length === this.option_fb.length;
  }

  actualizarRespuesta3(opcion: string, valor: string) {
    this.response_activities[opcion] = valor;
  }

  async onSelectionChange() {
    if (this.componentForm.value.state_id !== '') {
      const selectedStateId = this.componentForm.value.state_id;

      // Filtrar los municipios por state_id
      this.municipalityList = this.municipalityListAll.filter((municipality) => municipality.state_id === selectedStateId);

      // Resetear los campos de municipio y parroquia
      this.componentForm.get('municipality_id')!.reset([]);
      this.componentForm.get('parish_id')!.reset([]);
    }
  }

  onSelectionChange2() {
    if (this.componentForm.value.municipality_id != '') {
      const selectedMunicipalityId = this.componentForm.value.municipality_id;

      // Filtrar los municipios por state_id
      this.parishList = this.parishListAll.filter((parish) => parish.municipality_id === selectedMunicipalityId);

      this.componentForm.get('parish_id')!.reset([]);
    }
  }

  shouldShowAdditionalSection(): boolean {
    return this.selectedOption === 'si';
  }

  ngOnInit(): void {
    this.createForm();
    this.recaptchaV3Service.execute('6Le0bBMqAAAAAG2YEGl_OO2WuLszmNG4rOuWD4UX').subscribe((token) => {
      // Include the token in your form data or perform necessary actions
      this.recaptcha = token;
    });
    this.authService.getTokenService().subscribe((response) => {
      this.tokenService.setToken(response?.result?.token);

      this.getCurrentPosition();
    });
  }

  getCurrentPosition(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            resolve({ latitude: this.latitude, longitude: this.longitude });
          },
          (error) => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  createForm() {
    this.documentTypeList = [
      {
        documentKey: 'V',
        documentTypeName: 'Venezolano',
      },
      {
        documentKey: 'E',
        documentTypeName: 'Extranjero',
      },
    ];
    this.genderList = [
      {
        genderKey: 'female',
        genderName: 'Femenino',
      },
      {
        genderKey: 'male',
        genderName: 'Masculino',
      },
    ];

    this.phoneOperatorList = [
      {
        documentKey: '416',
        documentTypeName: '0416',
      },
      {
        documentKey: '426',
        documentTypeName: '0426',
      },
      {
        documentKey: '414',
        documentTypeName: '0414',
      },
      {
        documentKey: '424',
        documentTypeName: '0424',
      },
      {
        documentKey: '412',
        documentTypeName: '0412',
      },
    ];

    this.initialForm = this.formBuilder.group({
      documentNumber: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(8), Validators.pattern('^[0-9]*$')])],
      typeDocument: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
    });

    this.componentForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      birthdate: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(7)])],
      phoneOperator: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(4)])],
      phone2: ['', Validators.compose([Validators.minLength(11), Validators.maxLength(11)])],
      state_id: ['', Validators.compose([Validators.required])],
      municipality_id: ['', Validators.compose([Validators.required])],
      parish_id: ['', Validators.compose([Validators.required])],
      user_gender: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      marital_status: ['', Validators.compose([Validators.required])],
      street: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
      street2: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      house_number: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      reference_point: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      ethnicity: ['', Validators.compose([Validators.required])],
      educational_level: ['', Validators.compose([Validators.required])],
      is_inass_user: true,
    });

    this.socialForm = this.formBuilder.group({
      otherLivingWith: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      otherExpenseSource: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      permanentIncome: ['', Validators.required],
      housingCondition: ['', Validators.required],
      housingType: ['', Validators.required],
      housingOtherType: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.healthForm = this.formBuilder.group({
      otherDisease: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      institutionalResidency: [''],
      isInstitutionalizedResidencyCenter: ['', Validators.required],
      clapBenefit: ['', Validators.required],
      clapBenefitFrequency: [''],
      clapComplementOptions: ['', Validators.required],
      clapComplementFrecuency: [''],
      foodFrequency: ['', Validators.required],
      treatmentStatus: ['', Validators.required],
    });

    this.socialPartipitationForm = this.formBuilder.group({
      info_suministrate: ['', Validators.required],
      // frequencySelection: [''],
      otherSocialOrganization: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      // lawAtention: ['', Validators.required],
      shareKnowledge: ['', Validators.required],
      otherHabilities: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.familyForm = this.formBuilder.group({
      parentType: ['', Validators.required],
      name: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(7)])],
      phoneOperator: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(4)])],
      otherParents: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.inassForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(7)])],
      phoneOperator: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(4)])],
      collectionPlace: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      staffRelationship: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    });
  }

  goBackInitialInfo(): void {
    this.initial_info = true;
    this.basic_info = false;
    this.socioeconomic_data = false;
    this.basicHealthData = false;
    this.social_participation = false;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el desplazamiento sea suave
    });
  }

  goBackBasicInfo(): void {
    this.initial_info = false;
    this.basic_info = true;
    this.socioeconomic_data = false;
    this.basicHealthData = false;
    this.social_participation = false;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el desplazamiento sea suave
    });
  }

  goBackSocialInfo(): void {
    this.initial_info = false;
    this.basic_info = false;
    this.socioeconomic_data = true;
    this.basicHealthData = false;
    this.social_participation = false;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el desplazamiento sea suave
    });
  }

  goBackHealthInfo(): void {
    this.initial_info = false;
    this.basic_info = false;
    this.socioeconomic_data = false;
    this.basicHealthData = true;
    this.social_participation = false;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el desplazamiento sea suave
    });
  }

  goBackSocialParticipationInfo(): void {
    this.initial_info = false;
    this.basic_info = false;
    this.socioeconomic_data = false;
    this.basicHealthData = false;
    this.social_participation = true;
    this.worker_data = false;
    this.family_data = false;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el desplazamiento sea suave
    });
  }

  goBack(): void {}

  async toggleView(): Promise<void> {
    if (this.initialForm.valid) {
      if (this.recaptcha) {
        await this.checkIdentification();

        if (this.age < 60 && this.age != 0) {
          // Mostrar un mensaje de error
          const messagesData: CmmAlertToastrModel = {
            typeIcon: 'error',
            text: 'El usuario debe tener al menos 60 años',
          };

          // Abrimos la alerta con el mensaje
          this.dialogService.CmmAlertToastr(messagesData);
        } else {
          if (this.stateList.length == 0) {
            this.authService.getVenezuelanStates().subscribe(
              (data: LivingOption[]) => {
                this.stateList = data;
              },
              (error) => {
                this.expiratedTimeDialog();
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );
          }

          if (this.municipalityListAll.length == 0) {
            this.authService.getMunicipalitiesByStateService().subscribe(
              (data: LivingOption[]) => {
                this.municipalityListAll = data;
              },
              (error) => {
                this.expiratedTimeDialog();
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );
          }

          if (this.parishListAll.length == 0) {
            this.authService.getParishesByMunicipalityService().subscribe(
              (data: LivingOption[]) => {
                this.parishListAll = data;
              },
              (error) => {
                this.expiratedTimeDialog();
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );
          }

          this.authService.getCivilStateOptionsService().subscribe(
            (data: LivingOption[]) => {
              this.maritalStatusList = data;
            },
            (error) => {
              console.error('Error al llamar a la API:', error);
              if (error.status == 401) {
                this.expiratedTimeDialog();
              }
            }
          );

          this.authService.getEthnicityOptionsService().subscribe(
            (data: LivingOption[]) => {
              this.ethnicityList = data;

              this.componentForm.get('ethnicity')?.setValue(this.ethnicityList[0].id);
            },
            (error) => {
              console.error('Error al llamar a la API:', error);
              if (error.status == 401) {
                this.expiratedTimeDialog();
              }
            }
          );

          this.authService.getEducationalLevelService().subscribe(
            (data: LivingOption[]) => {
              this.educationalLevelList = data;
            },
            (error) => {
              console.error('Error al llamar a la API:', error);
              if (error.status == 401) {
                this.expiratedTimeDialog();
              }
            }
          );

          this.initial_info = false;
          this.basic_info = true;
          this.socioeconomic_data = false;
          this.basicHealthData = false;
          this.social_participation = false;
        }
      } else {
        const messagesData: CmmAlertToastrModel = {
          typeIcon: 'error',
          text: 'Error en validacion de recaptcha',
        };

        // Abrimos la alerta con el mensaje
        this.dialogService.CmmAlertToastr(messagesData);
      }
    } else {
      Object.keys(this.initialForm.controls).forEach((field) => {
        const control = this.initialForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  onRecaptchaResolved(token: string | null): void {
    this.token = token ?? ''; // Use an empty string if the token is null
    this.initialForm.get('recaptcha')?.setValue(this.token);
  }

  cleanPhoneNumber(phone: string): string {
    // Eliminar el signo '+' si está presente
    let cleanedPhone = phone.replace(/^\+/, '');

    // Si el número comienza con '0', eliminarlo
    if (cleanedPhone.startsWith('0')) {
      cleanedPhone = cleanedPhone.substring(1);
    }

    return cleanedPhone;
  }

  basicInfoView(): void {
    this.create_inass_user.name = this.create_inass_user.name ? this.create_inass_user.name : this.componentForm.value.name;
    this.create_inass_user.phone = '+58' + this.componentForm.value.phoneOperator + this.componentForm.value.phone;
    this.create_inass_user.email = this.componentForm.value.email;
    this.create_inass_user.mobile = this.componentForm.value.phone2! ? '+58' + this.cleanPhoneNumber(this.componentForm.value.phone2) : '';
    this.create_inass_user.user_gender = this.create_inass_user.user_gender ? this.create_inass_user.user_gender : this.componentForm.value.user_gender;
    this.create_inass_user.street = this.componentForm.value.street;
    this.create_inass_user.street2 = this.componentForm.value.street2;
    this.create_inass_user.house_number = this.componentForm.value.house_number;
    this.create_inass_user.reference_point = this.componentForm.value.reference_point;
    this.create_inass_user.civil_state = this.componentForm.value.marital_status;
    this.create_inass_user.ethnicity_ids = [this.componentForm.value.ethnicity];
    this.create_inass_user.educational_level = this.componentForm.value.educational_level;
    this.create_inass_user.municipality_id = this.componentForm.value.municipality_id;
    this.create_inass_user.state_id = this.componentForm.value.state_id;
    this.create_inass_user.parish_id = this.componentForm.value.parish_id;
    this.create_inass_user.is_inass_user = true;
    this.create_inass_user.latitude = this.latitude;
    this.create_inass_user.longitude = this.longitude;
    if (this.componentForm.valid) {
      const birthdateControl = this.componentForm.get('birthdate');
      if (birthdateControl && birthdateControl.value) {
        const dateParts = birthdateControl.value.split(/[-\/]/);
        if (dateParts.length === 3) {
          const day = dateParts[0].length === 4 ? dateParts[2].padStart(2, '0') : dateParts[1].padStart(2, '0'); // Añade un 0 delante si el día tiene solo un dígito
          const month = dateParts[0].length === 4 ? dateParts[1].padStart(2, '0') : dateParts[0].padStart(2, '0'); // Añade un 0 delante si el mes tiene solo un dígito
          const year = dateParts[0].length === 4 ? dateParts[0] : dateParts[2];

          // Formatear la fecha como 'YYYY-MM-DD'
          const birthDate = new Date(`${year}-${month}-${day}`);
          const today = new Date();

          // Calcular la edad
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          const dayDiff = today.getDate() - birthDate.getDate();

          // Ajustar la edad si el mes o el día del cumpleaños no han pasado aún este año
          if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
          }

          this.age = age;

          // Verificar si la edad es mayor o igual a 60
          if (age >= 60) {
            const formattedDate = `${year}-${month}-${day}`;
            this.create_inass_user.birthdate = formattedDate;
            birthdateControl.setValue(formattedDate);

            this.initial_info = false;
            this.basic_info = false;
            this.socioeconomic_data = true;
            this.basicHealthData = false;
            this.social_participation = false; // Cambio de variable
            this.progress = 20;
            this.tooltipText = '20%';
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Hace que el desplazamiento sea suave
            });
            this.authService.getLivingOptionsService().subscribe(
              (data: LivingOption[]) => {
                this.livingWithOptions = data.map((option) => option.name);
                this.livingWithAllOptions = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );

            this.authService.getExpenseConceptService().subscribe(
              (data: LivingOption[]) => {
                this.expenseConcept = data.map((option) => option.name);
                this.expenseConceptAll = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );

            this.authService.getIncomeConceptService().subscribe(
              (data: LivingOption[]) => {
                this.incomeSources = data.map((option) => option.name);
                this.incomeSourcesAll = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );

            this.authService.getHousingStatusService().subscribe(
              (data: LivingOption[]) => {
                this.housingConditions = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );

            this.authService.getHouseTypeService().subscribe(
              (data: LivingOption[]) => {
                this.housingTypes = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );
          } else {
            // Mostrar un mensaje de error
            birthdateControl.setErrors({ age: 'El usuario debe tener al menos 60 años' });
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: 'El usuario debe tener al menos 60 años',
            };

            // Abrimos la alerta con el mensaje
            this.dialogService.CmmAlertToastr(messagesData);
          }
        }
      }
    } else {
      // Opcional: mostrar mensajes de error para los campos del formulario
      Object.keys(this.componentForm.controls).forEach((field) => {
        const control = this.componentForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  selectionHousingType() {
    const otherHousingTypeControl = this.socialForm.get('housingOtherType');
    if (this.socialForm.value.housingType == 5) {
      this.isValidHousingType = true;
      otherHousingTypeControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
      this.housingOtherType = true;
    } else {
      otherHousingTypeControl?.clearValidators();
      this.housingOtherType = false;
      this.isValidHousingType = true;
    }
    otherHousingTypeControl?.updateValueAndValidity(); // Actualiza la validación del control
  }

  selectionParentType() {
    const otherParentsControl = this.familyForm.get('otherParents');

    if (this.familyForm.value.parentType === 'Otro') {
      this.otherParents = true;
      otherParentsControl?.setValidators([Validators.required]); // Añade el validador de 'required'
    } else {
      this.otherParents = false;
      otherParentsControl?.clearValidators(); // Elimina cualquier validador existente
    }

    otherParentsControl?.updateValueAndValidity(); // Actualiza la validación del control
  }

  updateLivingWithOption(option: string, isChecked: boolean) {
    const otherLivingWithControl = this.socialForm.get('otherLivingWith');

    if (option === 'Solo/Sola') {
      // Si "Solo/Sola" está seleccionada, limpia todas las demás opciones
      if (isChecked) {
        this.selectedLivingWithOptions = ['Solo/Sola'];
        this.otherLivingWith = false;
        otherLivingWithControl?.clearValidators(); // Elimina validación requerida si "Solo/Sola" está seleccionada
        otherLivingWithControl?.updateValueAndValidity(); // Actualiza el campo]
        this.errorMessage = '';
      } else {
        // Si "Solo/Sola" es deseleccionada, quítala de las opciones seleccionadas
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== 'Solo/Sola');
      }
    } else {
      // Si se selecciona cualquier otra opción
      if (isChecked) {
        // Si "Solo/Sola" está seleccionada, desmárcala
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== 'Solo/Sola');
        // Agrega la nueva opción
        this.selectedLivingWithOptions.push(option);
        this.errorMessage = '';
      } else {
        // Remueve la opción si se deselecciona
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== option);
      }
    }

    if (option === 'Otro') {
      if (isChecked) {
        this.otherLivingWith = true;
        otherLivingWithControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
        this.errorMessage = '';
      } else {
        this.otherLivingWith = false;
        otherLivingWithControl?.clearValidators(); // Elimina la validación requerida
        otherLivingWithControl?.reset(); // Limpia el valor del campo
      }
      otherLivingWithControl?.updateValueAndValidity(); // Actualiza la validación del campo
    }
  }

  isLivingWithSelected(option: string): boolean {
    return this.selectedLivingWithOptions.includes(option);
  }

  updateIncomeSource(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      if (isChecked) {
        this.selectedIncomeSources = ['Ninguna'];
        this.incomeErrorMessage = '';
      } else {
        this.selectedIncomeSources = this.selectedIncomeSources.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        this.selectedIncomeSources = this.selectedIncomeSources.filter((opt) => opt !== 'Ninguna');
        this.selectedIncomeSources.push(option);
        this.incomeErrorMessage = '';
      } else {
        this.selectedIncomeSources = this.selectedIncomeSources.filter((opt) => opt !== option);
      }
    }
  }

  isIncomeSourceSelected(option: string): boolean {
    return this.selectedIncomeSources.includes(option);
  }

  updateExpenseConcept(option: string, isChecked: boolean) {
    const otherExpenseSourceControl = this.socialForm.get('otherExpenseSource');

    if (option === 'Ninguno') {
      // Si "Ninguno" está seleccionado, deselecciona todas las demás opciones
      if (isChecked) {
        this.selectedExpenseConcept = ['Ninguno'];
        this.expenseErrorMessage = '';
        this.otherExpenseSource = false;
        otherExpenseSourceControl?.clearValidators(); // Elimina validación requerida
        otherExpenseSourceControl?.reset(''); // Limpia el valor del campo
      } else {
        // Si "Ninguno" es deseleccionado, quítalo de la lista
        this.selectedExpenseConcept = this.selectedExpenseConcept.filter((opt) => opt !== 'Ninguno');
      }
    } else {
      // Si se selecciona cualquier otra opción
      if (isChecked) {
        // Si "Ninguno" está seleccionado, desmárcalo
        this.expenseErrorMessage = '';
        this.selectedExpenseConcept = this.selectedExpenseConcept.filter((opt) => opt !== 'Ninguno');
        // Agrega la nueva opción seleccionada
        this.selectedExpenseConcept.push(option);
      } else {
        // Remueve la opción si se deselecciona
        this.selectedExpenseConcept = this.selectedExpenseConcept.filter((opt) => opt !== option);
      }
    }

    if (option === 'Otro') {
      if (isChecked) {
        this.otherExpenseSource = true;
        otherExpenseSourceControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
      } else {
        this.otherExpenseSource = false;
        otherExpenseSourceControl?.clearValidators(); // Elimina la validación requerida
        otherExpenseSourceControl?.reset(''); // Limpia el valor del campo
      }
      otherExpenseSourceControl?.updateValueAndValidity(); // Actualiza la validación del campo
    }
  }

  isExpenseConceptSelected(option: string): boolean {
    return this.selectedExpenseConcept.includes(option);
  }

  updateDisabilityOption(option: string, isChecked: boolean): void {
    if (option === 'Ninguna') {
      if (isChecked) {
        this.selectedDisabilities = ['Ninguna'];
        this.disabilityErrorMessage = '';
      } else {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== 'Ninguna');
        this.selectedDisabilities.push(option);
        this.disabilityErrorMessage = '';
      } else {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== option);
      }
    }
  }

  isDisabilitySelected(option: string): boolean {
    return this.selectedDisabilities.includes(option);
  }

  updateDiseaseOption(option: string, isChecked: boolean): void {
    const otherDiseaseControl = this.healthForm.get('otherDisease');

    if (option === 'Ninguna') {
      // Si "Ninguna" está seleccionada, deselecciona todas las demás opciones
      if (isChecked) {
        this.selectedDiseases = ['Ninguna'];
        this.otherDisease = false;
        this.diseaseErrorMessage = '';
        otherDiseaseControl?.clearValidators(); // Elimina la validación requerida
        otherDiseaseControl?.reset(); // Limpia el valor del campo
      } else {
        // Si "Ninguna" es deseleccionada, quítala de la lista
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      // Si se selecciona cualquier otra opción
      if (isChecked) {
        // Si "Ninguna" está seleccionada, desmárcala
        this.diseaseErrorMessage = '';
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== 'Ninguna');
        // Agrega la nueva opción seleccionada
        this.selectedDiseases.push(option);
      } else {
        // Remueve la opción si se deselecciona
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== option);
      }
    }

    if (option === 'Otro') {
      if (isChecked) {
        this.otherDisease = true;
        this.diseaseErrorMessage = '';
        otherDiseaseControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
      } else {
        this.otherDisease = false;
        otherDiseaseControl?.clearValidators(); // Elimina la validación requerida
        otherDiseaseControl?.reset(); // Limpia el valor del campo
      }
      otherDiseaseControl?.updateValueAndValidity(); // Actualiza la validación del campo
    }
  }

  isDiseaseSelected(option: string): boolean {
    return this.selectedDiseases.includes(option);
  }

  updateTreatmentCenterOption(option: string, isChecked: boolean): void {
    if (option === 'Ninguno') {
      if (isChecked) {
        this.treatmentCenterErrorMessage = '';
        this.selectedTreatmentCenters = ['Ninguno'];
      } else {
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== 'Ninguno');
      }
    } else {
      if (isChecked) {
        this.treatmentCenterErrorMessage = '';
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== 'Ninguno');
        this.selectedTreatmentCenters.push(option);
      } else {
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== option);
      }
    }
  }

  isTreatmentCenterSelected(option: string): boolean {
    return this.selectedTreatmentCenters.includes(option);
  }

  updateInstitutionalResidency(option: string) {
    const intitutionalControl = this.healthForm.get('institutionalResidency');
    this.isValidResidency = true;
    if (option === 'si') {
      intitutionalControl?.setValidators([Validators.required]);
      this.institutionalResidency = true;
    } else {
      this.institutionalResidency = false;
      intitutionalControl?.clearValidators(); // Elimina la validación requerida
      intitutionalControl?.reset(); // Limpia el valor del campo
    }
    intitutionalControl?.updateValueAndValidity(); // Actualiza la validación del campo
  }

  updateClapFrecuency(option: boolean) {
    const clapFrecuencyControl = this.healthForm.get('clapBenefitFrequency');
    this.isValidClapBenefit = true;
    if (option === true) {
      clapFrecuencyControl?.setValidators([Validators.required]);
      this.clapFrecuency = true;
    } else {
      this.clapFrecuency = false;
      clapFrecuencyControl?.clearValidators(); // Elimina la validación requerida
      clapFrecuencyControl?.reset(); // Limpia el valor del campo
    }
    clapFrecuencyControl?.updateValueAndValidity(); // Actualiza la validación del campo
  }

  updateClapComplementFrecuency(option: boolean) {
    const clapComplementFrecuencyControl = this.healthForm.get('clapComplementFrecuency');
    this.isValidClapComplement = true;
    if (option === true) {
      clapComplementFrecuencyControl?.setValidators([Validators.required]);
      this.clapComplementFrecuency = true;
    } else {
      this.clapComplementFrecuency = false;
      clapComplementFrecuencyControl?.clearValidators(); // Elimina la validación requerida
      clapComplementFrecuencyControl?.reset(); // Limpia el valor del campo
    }
    clapComplementFrecuencyControl?.updateValueAndValidity(); // Actualiza la validación del campo
  }

  async verifySocioeconomicData(): Promise<void> {
    if (this.selectedLivingWithOptions.length === 0) {
      this.errorMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedIncomeSources.length === 0) {
      this.incomeErrorMessage = 'Debe seleccionar al menos una fuente de ingreso.';
    }

    if (this.selectedExpenseConcept.length === 0) {
      this.expenseErrorMessage = 'Debe seleccionar al menos un concepto.';
    }

    if (this.errorMessage || this.incomeErrorMessage || this.expenseErrorMessage) {
      return;
    }
  }

  async toggleSocioeconomicData(): Promise<void> {
    if (this.socialForm.valid) {
      await this.verifySocioeconomicData();
      this.selectedLivingWithIds = this.selectedLivingWithOptions
        .map((option) => {
          const match = this.livingWithAllOptions.find((livingWithOption) => livingWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selectedIncomeSourceIds = this.selectedIncomeSources
        .map((option) => {
          const match = this.incomeSourcesAll.find((incomeSourceOption) => incomeSourceOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selectedExpenseConceptIds = this.selectedExpenseConcept
        .map((option) => {
          const match = this.expenseConceptAll.find((expenseConceptOption) => expenseConceptOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.basic_info = false;
      this.socioeconomic_data = false;
      this.basicHealthData = true;
      this.social_participation = false;
      this.progress = 40;
      this.tooltipText = '40%';

      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Hace que el desplazamiento sea suave
      });

      // Llamadas a los servicios API
      this.authService.getDisabilityService().subscribe(
        (data: LivingOption[]) => {
          this.disabilities = data.map((option) => option.name);
          this.allDisabilities = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );

      this.authService.getDiseaseService().subscribe(
        (data: LivingOption[]) => {
          this.diseases = data.map((option) => option.name);
          this.allDiseases = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );

      this.authService.getTreatmentPlaceService().subscribe(
        (data: LivingOption[]) => {
          this.treatmentCenters = data.map((option) => option.name);
          this.allTreatmentCenters = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );

      this.authService.getFeedingFrequencyService().subscribe(
        (data: LivingOption[]) => {
          this.foodFrequencyOptions = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );

      this.authService.getInstitutionalResidencyService().subscribe(
        (data: LivingOption[]) => {
          this.isInstitutionalizedResidencyCenter = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );

      this.authService.getClapBenefitFrequencyService().subscribe(
        (data: LivingOption[]) => {
          this.clapBenefitFrequency = data;
          this.clapComplementFrequency = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );
    } else {
      this.isValidIncome = this.socialForm.value.permanentIncome.length > 0 ? true : false;
      this.isValidHousing = this.socialForm.value.housingCondition.length > 0 ? true : false;
      this.isValidHousingType = this.socialForm.value.selectionHousingType > 0 ? true : false;
      await this.verifySocioeconomicData();
      Object.keys(this.socialForm.controls).forEach((field) => {
        const control = this.socialForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  async validateHealthData(): Promise<void> {
    if (this.selectedDiseases.length === 0) {
      this.diseaseErrorMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedTreatmentCenters.length === 0) {
      this.treatmentCenterErrorMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedDisabilities.length === 0) {
      this.disabilityErrorMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.diseaseErrorMessage || this.treatmentCenterErrorMessage || this.disabilityErrorMessage) {
      return;
    }
  }

  async toggleHealthData(): Promise<void> {
    if (this.healthForm.valid) {
      await this.validateHealthData();
      this.selectedDisabilitiesWithIds = this.selectedDisabilities
        .map((option) => {
          const match = this.allDisabilities.find((disabilitiesWithOption) => disabilitiesWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selecteddiseasesWithIds = this.selectedDiseases
        .map((option) => {
          const match = this.allDiseases.find((diseasesWithOption) => diseasesWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selectedTreatmentCentersWithIds = this.selectedTreatmentCenters
        .map((option) => {
          const match = this.allTreatmentCenters.find((treatmentWithOption) => treatmentWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.basic_info = false;
      this.socioeconomic_data = false;
      this.basicHealthData = false;
      this.social_participation = true; // Cambio de variable
      this.progress = 60;
      this.tooltipText = '60%';
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Hace que el desplazamiento sea suave
      });
      this.authService.getCommunicationMediumsService().subscribe(
        (data: LivingOption[]) => {
          this.option_fb = data.map((option) => option.name);
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );

      this.authService.getCommunicationFrequenciesService().subscribe(
        (data: LivingOption[]) => {
          this.frequencyList = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );

      this.authService.getSocialOrganizationsService().subscribe(
        (data: LivingOption[]) => {
          this.organization = data.map((option) => option.name);
          this.allOrganization = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );

      this.authService.getSocialMissionsService().subscribe(
        (data: LivingOption[]) => {
          this.social_mission = data.map((option) => option.name);
          this.socialMisionsAll = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );

      this.authService.getActivityTypesService().subscribe(
        (data: LivingOption[]) => {
          this.activities = data.map((option) => option.name);
          this.allActivities = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );

      this.authService.getSkillsService().subscribe(
        (data: LivingOption[]) => {
          this.habilities = data.map((option) => option.name);
          this.allHabilities = data;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );
    } else {
      this.isValidTreatment = this.healthForm.value.treatmentStatus.length > 0 ? true : false;
      this.isValidResidency = this.healthForm.value.isInstitutionalizedResidencyCenter.length > 0 ? true : false;
      this.isValidClapBenefit = this.healthForm.value.clapBenefit.length > 0 ? true : this.healthForm.value.clapBenefitFrequency.length > 0 ? true : false;
      this.isValidClapComplement = this.healthForm.value.clapComplementOptions.length > 0 ? true : this.healthForm.value.clapComplementFrecuency.length > 0 ? true : false;
      this.isValidFood = this.healthForm.value.foodFrequency.length > 0 ? true : false;
      await this.validateHealthData();
      Object.keys(this.healthForm.controls).forEach((field) => {
        const control = this.healthForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  updateOrganizationOption(option: string, isChecked: boolean) {
    const otherSocialOrganizationControl = this.socialPartipitationForm.get('otherSocialOrganization');

    if (option === 'Ninguna') {
      // Si "Ninguna" es seleccionada, deselecciona todas las demás opciones
      if (isChecked) {
        this.errorOrganizationMessage = '';
        this.selectedOrganizationOptions = ['Ninguna'];
        this.otherSocialOrganization = false; // Oculta el campo 'Otro' si estaba visible
        otherSocialOrganizationControl?.clearValidators(); // Elimina la validación requerida
        otherSocialOrganizationControl?.reset(); // Limpia el valor del campo
      } else {
        // Si "Ninguna" es deseleccionada, quítala de la lista
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      // Si se selecciona cualquier otra opción
      if (isChecked) {
        this.errorOrganizationMessage = '';
        // Si "Ninguna" estaba seleccionada, deseleccionarla
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== 'Ninguna');
        // Agrega la nueva opción seleccionada
        this.selectedOrganizationOptions.push(option);
      } else {
        // Remueve la opción si se deselecciona
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== option);
      }
    }

    if (option === 'Otro') {
      if (isChecked) {
        this.errorOrganizationMessage = '';
        this.otherSocialOrganization = true;
        otherSocialOrganizationControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
      } else {
        this.otherSocialOrganization = false;
        otherSocialOrganizationControl?.clearValidators(); // Elimina la validación requerida
        otherSocialOrganizationControl?.reset(); // Limpia el valor del campo
      }
      otherSocialOrganizationControl?.updateValueAndValidity(); // Actualiza la validación del campo
    }
  }

  isOrganizationSelected(option: string): boolean {
    return this.selectedOrganizationOptions.includes(option);
  }

  updateSocialMissionOption(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      if (isChecked) {
        // Si "Ninguna" es seleccionada, deselecciona todas las demás opciones
        this.selectedSocialMissionOptions = ['Ninguna'];
        this.errorMisionMessage = '';
      } else {
        // Si "Ninguna" es deseleccionada, remuévela del array
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        // Si "Ninguna" está seleccionada, deseleccionarla
        this.errorMisionMessage = '';
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== 'Ninguna');
        // Añadir la nueva opción
        this.selectedSocialMissionOptions.push(option);
      } else {
        // Remover la opción si es deseleccionada
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== option);
      }
    }
  }

  isSocialMissionSelected(option: string): boolean {
    return this.selectedSocialMissionOptions.includes(option);
  }

  updateActivityOption(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      if (isChecked) {
        // Si "Ninguna" es seleccionada, deselecciona todas las demás opciones
        this.selectedActivityOptions = ['Ninguna'];
        this.errorActivitiesMessage = '';
      } else {
        // Si "Ninguna" es deseleccionada, remuévela del array
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        // Si "Ninguna" está seleccionada, deseleccionarla
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== 'Ninguna');
        // Añadir la nueva opción
        this.selectedActivityOptions.push(option);
        this.errorActivitiesMessage = '';
      } else {
        // Remover la opción si es deseleccionada
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== option);
      }
    }
  }

  isActivitySelected(option: string): boolean {
    return this.selectedActivityOptions.includes(option);
  }

  updateHabilitiesOption(option: string, isChecked: boolean) {
    const otherHabilitiesControl = this.socialPartipitationForm.get('otherHabilities');

    if (isChecked) {
      // Añadir la opción seleccionada
      this.selectedHabilitiesOptions.push(option);

      this.habilitiesErrorMessage = '';

      if (option === 'Otro') {
        this.otherHabilities = true;
        otherHabilitiesControl?.setValidators([Validators.required]); // Hace que sea requerido si está seleccionado
      }
    } else {
      // Remover la opción deseleccionada
      this.selectedHabilitiesOptions = this.selectedHabilitiesOptions.filter((opt) => opt !== option);

      if (option === 'Otro') {
        this.otherHabilities = false;
        otherHabilitiesControl?.clearValidators(); // Elimina la validación requerida
        otherHabilitiesControl?.reset(); // Limpia el valor del campo
      }
    }

    otherHabilitiesControl?.updateValueAndValidity();

    // Verificar si no hay ninguna opción seleccionada y mostrar el mensaje de error
    if (this.selectedHabilitiesOptions.length === 0) {
      this.habilitiesErrorMessage = 'Debe seleccionar al menos una habilidad o destreza.';
    } else {
      this.habilitiesErrorMessage = '';
    }
  }

  isHabilitySelected(option: string): boolean {
    return this.selectedHabilitiesOptions.includes(option);
  }

  async validateSocialData(): Promise<void> {
    this.isValid = this.option_fb.every((opcion) => this.response_fb[opcion] !== undefined);

    if (this.isValid == false) {
      this.isValid = false;
    }

    if (this.selectedOrganizationOptions.length === 0) {
      this.errorOrganizationMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedSocialMissionOptions.length === 0) {
      this.errorMisionMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedActivityOptions.length === 0) {
      this.errorActivitiesMessage = 'Debe seleccionar al menos una opción.';
    }

    if (this.selectedHabilitiesOptions.length === 0) {
      this.habilitiesErrorMessage = 'Debe seleccionar al menos una habilidad o destreza.';
    }

    if (!this.isValid || this.errorOrganizationMessage || this.errorMisionMessage || this.errorActivitiesMessage || this.habilitiesErrorMessage) {
      return;
    }
  }

  async toggleSocialData(): Promise<void> {
    if (this.socialPartipitationForm.valid) {
      await this.validateSocialData();

      this.selectedOrganizationsIds = this.selectedOrganizationOptions
        .map((option) => {
          const match = this.allOrganization.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selectedSocialIds = this.selectedSocialMissionOptions
        .map((option) => {
          const match = this.socialMisionsAll.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.selectedActivityIds = this.selectedActivityOptions
        .map((option) => {
          const match = this.allActivities.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      this.skillsIds = this.selectedHabilitiesOptions
        .map((option) => {
          const match = this.allHabilities.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      let data = {
        params: {
          ...this.create_inass_user,
          living_with_ids: this.selectedLivingWithIds,
          other_living_with: this.socialForm.value.otherLivingWith,
          income_source_ids: this.selectedIncomeSourceIds,
          expense_source_ids: this.selectedExpenseConceptIds,
          other_expense_source: this.socialForm.value.otherExpenseSource,
          housing_status: this.socialForm.value.housingCondition,
          house_type_id: this.socialForm.value.housingType,
          other_house_type: this.socialForm.value.housingOtherType,
          disease_ids: this.selecteddiseasesWithIds,
          otherDisease: this.otherDisease,
          disability_ids: this.selectedDisabilitiesWithIds,
          treatment_status: this.healthForm.value.treatmentStatus,
          treatment_place_ids: this.selectedTreatmentCentersWithIds,
          feeding_frequency: this.healthForm.value.foodFrequency,
          residency_center: this.healthForm.value.institutionalResidency,
          is_institutionalized_residency_center: this.healthForm.value.isInstitutionalizedResidencyCenter,
          clap_benefit: this.healthForm.value.clapBenefit,
          clap_benefit_frequency: this.healthForm.value.clapBenefitFrequency,
          clap_complement: this.healthForm.value.clapComplementOptions,
          clap_complement_frequency: this.healthForm.value.clapComplementFrecuency,
          communication_usages: this.response_fb,
          social_organization_ids: this.selectedOrganizationsIds,
          other_social_organization: this.socialPartipitationForm.value.otherSocialOrganization,
          social_mission_ids: this.selectedSocialIds,
          activity_type_ids: this.selectedActivityIds,
          skill_ids: this.skillsIds,
          knows_elderly_care_law: true,
          share_knowledge: true,
          other_skill: this.socialPartipitationForm.value.otherHabilities,
        },
      };

      this.authService.createInassUserService(data).subscribe(
        async (data) => {
          if (data.result.error) {
            if (data.result.code == 401) {
              this.expiratedTimeDialog();
            } else {
              // Armamos la data de la alerta
              const messagesData: CmmAlertToastrModel = {
                typeIcon: 'error',
                text: data.result.error,
              };

              // Abrimos la alerta con el mensaje
              this.dialogService.CmmAlertToastr(messagesData);
            }
          } else {
            this.inass_user_id = data.result.res_partner_id;
            this.inass_survey_id = data.result.inass_survey_id;

            const institutionalResidencys = this.healthForm.value.institutionalResidency ? 'Si/' + this.healthForm.value.isInstitutionalizedResidencyCenter : 'No/Ninguno';
            const clapBenefits = this.healthForm.value.clapBenefit ? 'Si/' + this.healthForm.value.clapBenefitFrequency : 'No/Nunca';
            const clapComplements = this.healthForm.value.clapComplementOptions ? 'No/' + this.healthForm.value.clapComplementFrecuency : 'No/Nunca';

            const houseType = this.housingTypes.find((item) => item.id === this.socialForm.value.housingType)?.name;

            const infoSuministrate = this.infoSources.find((item) => item.value === this.socialPartipitationForm.value.info_suministrate)?.label;

            let data2 = {
              params: {
                survey_input_lines: [
                  JSON.stringify(this.selectedLivingWithOptions) + ' + 1. ¿Con quién vive usted?',
                  JSON.stringify(this.selectedIncomeSources) + ' + 2. Seleccione por cuál de estos conceptos recibe usted ingresos económicos:',
                  this.socialForm.value.permanentIncome + ' + 3. ¿Recibe ingresos económicos de manera permanente?',
                  this.socialForm.value.housingCondition + ' + 5. Vivienda:',
                  JSON.stringify(this.selectedExpenseConcept) + ' + 4. El ingreso que percibe le alcanza para:',
                  houseType + ' + 6. Tipo de vivienda:',
                  `${JSON.stringify(this.selectedDisabilities)} + 7. ¿Tiene alguna discapacidad?`,
                  `${JSON.stringify(this.selectedDiseases)} + 8. ¿Usted posee alguna de estas enfermedades - patologías?`,
                  `${JSON.stringify(this.healthForm.value.treatmentStatus)} + 9. ¿Recibe tratamiento o rehabilitación en algún centro médico?`,
                  `${JSON.stringify(this.selectedTreatmentCenters)} + 10. Indique dónde atiende sus temas de salud:`,
                  `${institutionalResidencys} + 11. ¿Usted se encuentra institucionalizado en algún centro residencial?`,
                  `${clapBenefits} + 12. ¿Usted tiene acceso a los alimentos CLAP en su Comunidad?`,
                  `${clapComplements} + 13. ¿Usted recibe el complemento CLAP por los Círculos de abuelos?`,
                  `${this.healthForm.value.foodFrequency} + 14. ¿Con qué frecuencia se alimenta usted?`,
                  `${JSON.stringify(this.response_fb)} + 15. ¿Con qué frecuencia utiliza usted los siguientes medios de comunicación?`,
                  `${JSON.stringify(this.selectedOrganizationOptions)} + 16. ¿Participa usted en alguna Organización Social?`,
                  `${JSON.stringify(this.selectedSocialMissionOptions)} + 17. ¿Ha sido beneficiado en el último año por alguna Misión Social?`,
                  `${JSON.stringify(this.selectedActivityOptions)} + 18. ¿Le gustaría participar en las siguientes actividades?`,
                  `${JSON.stringify(this.selectedHabilitiesOptions)} + 19. Indique sus principales habilidades y destrezas:`,
                  `${this.socialPartipitationForm.value.shareKnowledge} + 20. ¿Le gustaría compartir sus conocimientos, saberes y tradiciones?`,
                  // `${this.socialPartipitationForm.value.lawAtention} + 16. ¿Conoce la Ley Orgánica para la Atención y Desarrollo Integral de las Personas Adultas Mayores?`,
                  `${infoSuministrate} + 21. La información fue suministrada por:`,
                ],
              },
            };
            const register = await this.registerResponses(data2); // Usa await aquí
            this.progress = 80;
            this.tooltipText = '80%';
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Hace que el desplazamiento sea suave
            });
            if (this.socialPartipitationForm.value.info_suministrate == 'self') {
              let data = {
                params: {
                  completed_by: this.socialPartipitationForm.value.info_suministrate,
                },
              };
              this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
                async (data) => {
                  if (data.result.error) {
                    if (data.result.code == 401) {
                      this.expiratedTimeDialog();
                    } else {
                      // Armamos la data de la alerta
                      const messagesData: CmmAlertToastrModel = {
                        typeIcon: 'error',
                        text: data.result.error,
                      };

                      // Abrimos la alerta con el mensaje
                      this.dialogService.CmmAlertToastr(messagesData);
                    }
                  } else {
                    this.social_participation = false;
                    this.id_hash_pdf = data.result.idhash;
                    this.registerCompleted = true;
                    this.progress = 100;
                    this.tooltipText = '100%';
                  }
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  if (error.status == 401) {
                    this.expiratedTimeDialog();
                  }
                }
              );
            }

            if (this.socialPartipitationForm.value.info_suministrate == 'family') {
              this.social_participation = false;
              this.family_data = true; // Cambio de variable
              this.authService.getFamilyRelationshipService().subscribe(
                (data: LivingOption[]) => {
                  this.relations = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
            }

            if (this.socialPartipitationForm.value.info_suministrate == 'staff') {
              this.social_participation = false;
              this.worker_data = true; // Cambio de variable
              this.authService.getCollectionPlacesService().subscribe(
                (data: LivingOption[]) => {
                  this.campusList = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
              this.authService.getStaffReasonService().subscribe(
                (data: LivingOption[]) => {
                  this.captureReasons = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
            }
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );
    } else {
      this.isShareKnowledge = this.socialPartipitationForm.value.shareKnowledge.length > 0 ? true : false;
      this.isInfoSuministrate = this.socialPartipitationForm.value.info_suministrate.length > 0 ? true : false;
      await this.validateSocialData();
      Object.keys(this.socialPartipitationForm.controls).forEach((field) => {
        const control = this.socialPartipitationForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  toggleFamilyData(): void {
    if (this.familyForm.valid) {
      let data = {
        params: {
          completed_by: 'family',
          family_relationship: this.familyForm.value.parentType,
          other_family_relationship: this.familyForm.value.otherParents,
          family_member: {
            name: this.familyForm.value.name ? this.familyForm.value.name : this.nameFamilyForm,
            vat: this.familyForm.value.vat,
            phone: '+58' + this.familyForm.value.phoneOperator + this.familyForm.value.phone,
            user_nationality: this.familyForm.value.user_nationality,
          },
        },
      };
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
        (data) => {
          if (data.result.error) {
            if (data.result.code == 401) {
              this.expiratedTimeDialog();
            } else {
              // Armamos la data de la alerta
              const messagesData: CmmAlertToastrModel = {
                typeIcon: 'error',
                text: data.result.error,
              };

              // Abrimos la alerta con el mensaje
              this.dialogService.CmmAlertToastr(messagesData);
            }
          } else {
            this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
              (data) => {
                this.family_data = false;
                this.id_hash_pdf = data.result.idhash;
                this.registerCompleted = true;
                this.progress = 100;
                this.tooltipText = '100%';
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );
    } else {
      Object.keys(this.familyForm.controls).forEach((field) => {
        const control = this.familyForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  toggleInassData(): void {
    if (this.inassForm.valid) {
      let data = {
        params: {
          completed_by: 'staff',
          staff_relationship: this.inassForm.value.staffRelationship,
          collection_place: this.inassForm.value.collectionPlace,
          staff_member: {
            name: this.inassForm.value.name ? this.inassForm.value.name : this.nameOperatorForm,
            vat: this.inassForm.value.vat,
            phone: '+58' + this.inassForm.value.phoneOperator + this.inassForm.value.phone,
            // user_nationality: this.inassForm.value.user_nationality,
          },
        },
      };
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
        (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.dialogService.CmmAlertToastr(messagesData);
          } else {
            this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
              (data) => {
                this.worker_data = false;
                this.id_hash_pdf = data.result.idhash;
                this.registerCompleted = true;
                this.progress = 100;
                this.tooltipText = '100%';
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                if (error.status == 401) {
                  this.expiratedTimeDialog();
                }
              }
            );
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          if (error.status == 401) {
            this.expiratedTimeDialog();
          }
        }
      );
    } else {
      Object.keys(this.inassForm.controls).forEach((field) => {
        const control = this.inassForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  reloadView() {
    location.reload();
  }

  getPdf() {
    this.authService.SurveyPdfService(this.id_hash_pdf).subscribe(
      (pdfBlob) => {
        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        // Abrir el PDF en una nueva pestaña
        window.open(url);

        this.registerCompleted = true;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        if (error.status == 401) {
          this.expiratedTimeDialog();
        }
      }
    );
  }

  registerResponses(data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe({
        next: (response) => {
          if (response.result.error) {
            if (response.result.code == 401) {
              this.expiratedTimeDialog();
            } else {
              // Armamos la data de la alerta
              const messagesData: CmmAlertToastrModel = {
                typeIcon: 'error',
                text: response.result.error,
              };
              // Abrimos la alerta con el mensaje
              this.dialogService.CmmAlertToastr(messagesData);

              resolve(false);
            }
          } else {
            resolve(true);
          }
        },
        error: (err) => {
          // En caso de error en la llamada al servicio
          // Puedes agregar un manejo de errores aquí si lo necesitas.
          reject(err);
        },
      });
    });
  }

  onSubmit() {
    this.componentForm.markAllAsTouched();
    return;
  }

  expiratedTimeDialog() {
    //*Si ya el diálogo está abierto, no lo vuelvo a abrir
    if (this.expiratedSessionDialogOpen) {
      return;
    }

    // Indicamos que se abrio el dialogo
    this.expiratedSessionDialogOpen = true;

    // Armamos la data de la alerta
    const messagesData: CmmAlertModalModel = {
      title: 'Su sesión expiró',
      text: '',
      typeIcon: 'error',
      showCancelButton: true,
      cancelButtonText: 'Reiniciar registro',
      showConfirmButton: false,
      confirmButtonText: 'Mantener sesión',
      timeLeft: 10,
    };

    // Abrimos la alerta con el mensaje
    this.dialogService
      .CmmOpenCloseSessionDialog(messagesData)
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((result) => {
        // En caso de que el usuario confirma
        if (result == 'confirm') {
          // Indicamos que se cerro el dialogo
          this.expiratedSessionDialogOpen = false;
        }

        // En cualquier otro caso, Si decide terminar la session
        else {
          // Indicamos que se cerro el dialogo
          this.expiratedSessionDialogOpen = false;
          this.reloadView();
        }
      });
  }

  checkIncome() {
    this.isValidIncome = true;
  }

  checkHousing() {
    this.isValidHousing = true;
  }

  checkTreatment() {
    this.isValidTreatment = true;
  }

  checkShare() {
    this.isShareKnowledge = true;
  }

  checkInfo() {
    this.isInfoSuministrate = true;
  }

  checkFrecuencyBenefitClap() {
    this.isValidClapBenefit = true;
  }

  checkFrecuencyComplementClap() {
    this.isValidClapComplement = true;
  }

  checkValidFood() {
    this.isValidFood = true;
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
